.container{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
}


.header{
  width: 500px;
  height: 200px;
  /* outline: solid 1px rgb(216, 11, 11); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer{
  width: 500px;
  height: 120px;
  /* outline: solid 1px rgb(216, 11, 11); */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.moto{
  font-family: "Audiowide", sans-serif;
  font-weight: 400;
}
/* 
.address {
  font-family: "Audiowide", sans-serif;
  font-weight: 100;
} */
