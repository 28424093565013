html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-image: url('./images/lioness_background_1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  color: white;
  
}

img {
  width: 500px;
}

a:link{
  text-decoration: none;
  color: #ffffff;
}

/* Mobile Responsiveness settings */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 768px) {
  /* html, body {
    max-height: fit-content;
    height: 100vmin;
  } */

  .container{
    /* outline: solid 1px rgb(216, 11, 11); */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100svw;
  }
  
  
  .header{
    width: 100%;
    height: 100px;
    /* outline: solid 1px rgb(216, 11, 11); */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .midsection {
    /* outline: solid 1px rgb(216, 11, 11); */
    font-size: smaller;
    font-weight: 50;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  
  .footer{
    font-size: smaller;
    width: 100%;
    height: 60px;
    /* outline: solid 1px rgb(216, 11, 11); */
    display: flex; 
    justify-content: center;
    align-items: center;  
    margin-bottom: 25px;
  }

  img {
    width: 350px;
  }

}




@media only screen and (max-width: 500px) {

  img {
    width: 250px;
  }


  .midsection {
    /* outline: solid 1px rgb(216, 11, 11); */
    font-size: smaller;
    width: 60%;
    display:flex;
    align-items: center; 
    justify-content: center;
    /* margin-left: 100px; */
  }

  .footer{
    /* outline: solid 1px rgb(216, 11, 11); */
    margin-bottom: 0px;
  }



}